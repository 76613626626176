import React, { useState } from 'react'
import { Link } from 'gatsby'
import MouseTooltip from 'react-sticky-mouse-tooltip'
import HoverVideoPlayer from 'react-hover-video-player'
import {
  Wrapper,
} from '../../../components'
import { withTrans } from '../../../i18n/withTrans'
import video1 from '../../../assets/videos/v02/01_Desktop_Cargo.mp4'
import video2 from '../../../assets/videos/v02/01_Desktop_Passenger.mp4'
import CargoFilled from '../../../assets/icons/TAM_text_cargo_filled_two.svg'
import PassegersFillerd from '../../../assets/icons/TAM_text_passenger_filled.svg'
import CargoOutLined from '../../../assets/icons/TAM_text_cargo_outlined_two.svg'
import PassegersOutLined from '../../../assets/icons/TAM_text_passenger_outlined.svg'

const WhatWeOfferViewDesktop = ({ t }) => {
  const [cargoWordOut, setCargoWordOut] = useState(true)
  const [passegerWordOut, setPassegerWordOut] = useState(true)

  return (
    <div
      className="container-what-we-offer"
      // onMouseMove={onMouseMove.bind(this)}
    >
      <Link
        to="/what-we-offer/cargo/"
        state={{ type: 'cargo' }}
      >
        <div className="box-video left">
          <HoverVideoPlayer
            style={{
              width: '200%',
              position: 'absolute',
              // height: 'calc(100vh - 100px)',
              top: '50%',
              left: '50%',
              height: 'calc(100vh - 100px)',
              transform: 'translate(-50%, -50%)',
            }}
            onHoverStart={() => {
              setCargoWordOut(false)
            }}
            onHoverEnd={() => {
              setCargoWordOut(true)
            }}
            hoverOverlay={
              <div className="box-word-video">
                <Wrapper
                  marginD="0"
                  style={{
                    height: '140px',
                    width: '100%',
                  }}
                  widthD='300px'>

                  <CargoFilled style={{ fill: '#fff' }}/>
                </Wrapper>
              </div>
            }
            videoSrc={video1}
          />
          {
            cargoWordOut
            && (
              <div className="box-word-video full">
                <Wrapper
                  marginD="0"
                  style={{
                    height: '140px',
                    width: '300px',
                  }}
                  widthD='300px'>

                  <CargoOutLined style={{ stroke: '#fff' }}/>
                </Wrapper>
              </div>)
          }

        </div>
      </Link>
      <Link
        to="/what-we-offer/passenger/"
        state={{ type: 'passenger' }}>
        <div className="box-video">
          <HoverVideoPlayer
            // disablePictureInPicture={false}
            onHoverStart={() => {
              setPassegerWordOut(false)
            }}
            onHoverEnd={() => {
              setPassegerWordOut(true)
            }}
            style={{
              width: '200%',
              position: 'absolute',
              // height: 'calc(100vh - 100px)',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
            hoverOverlay={
              <div className="box-word-video">
                <Wrapper
                  marginD="0"
                  style={{
                    height: '140px',
                    width: '100%',
                  }}
                  widthD='600px'>

                  <PassegersFillerd style={{ fill: '#fff' }}/>
                </Wrapper>
              </div>
            }
            videoSrc={video2}
          />
          {
            passegerWordOut
            && (
              <div className="box-word-video full">
                <Wrapper
                  marginD="0"
                  style={{
                    height: '140px',
                    width: '100%',
                  }}
                  widthD='600px'>

                  <PassegersOutLined style={{ stroke: '#fff' }}/>

                </Wrapper>
              </div>)
          }

        </div>
      </Link>
      <MouseTooltip
        visible={true}
        offsetX={5}
        offsetY={5}
        style={{
          zIndex: 100000,
        }}
      >
        <div className="cursor-what-we-offer">

          <p className="cursor-text">{t('offer.buttons.view')}</p>

        </div>

      </MouseTooltip>

    </div>
  )
}

export default withTrans(WhatWeOfferViewDesktop)