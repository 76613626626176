import _ from 'lodash'
import React from 'react'
import { isTablet } from 'react-device-detect'
import Helmet from 'react-helmet'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { useWindowDimensions } from '../../helpers'
import WhatWeOfferViewDesktop from './desktop-view'
import WhatWeOfferViewIpad from './ipad-view'
import WhatWeOfferViewMobile from './mobile-view'

import {
  Seo,
} from '../../components'
import './what-we-offer-style.scss'

const WhatWeOfferView = ({ data }) => {
  const breakpoints = useBreakpoint()
  const whatWeOfferView = () => {
    switch (true) {
      case breakpoints.mdM && !isTablet:
        return <WhatWeOfferViewDesktop />
      case isTablet:
        return <WhatWeOfferViewIpad />
      case breakpoints.sm:
        return <WhatWeOfferViewIpad />
      default:
        return <WhatWeOfferViewMobile />
    }
  }
  return (
    <React.Fragment>
      <Helmet
        bodyAttributes={{
          class: 'overlay',
        }}
      />
      <Seo
        title={_.get(data, 'yoast_head_json.title')}
        description={_.get(data, 'yoast_head_json.description')}
        schema={_.get(data, 'yoast_head_json.schema')}
        type={_.get(data, 'yoast_head_json.og_type', {})}
      />
      {whatWeOfferView()}
    </React.Fragment>

  )
}

export default WhatWeOfferView
