import React, { useRef, useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { withTrans } from '../../../i18n/withTrans'
import passengerGift from '../../../assets/videos/gifs/Cargo_Passenger_m.gif'

const WhatWeOfferViewMobile = ({ t }) => {
  const [noPlacehoder, setNoPlacehoder] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setNoPlacehoder(true)
    }, 10000)
    return () => clearTimeout(timeout)
  }, [])

  return (
    <div className="what-we-offer-mobile-container">
      <div className="what-we-offer-mobile-link">
        <Link
          to="/what-we-offer/cargo/"
          state={{ type: 'cargo' }}
        >
          <p>{t('offer.buttons.cargo')}</p>
        </Link>
        <Link
          to="/what-we-offer/passenger/"
          state={{ type: 'passenger' }}
        >
          <p>{t('offer.buttons.passenger')}</p>
        </Link>

      </div>
      <div className="what-we-offer-mobile-box">
        <div className={noPlacehoder ? 'placeholder noplacehonder' : 'placeholder'}>

          <div className="video" style={{
            backgroundImage: `url(${passengerGift})`,
          }}></div>

        </div>
      </div>
    </div>

  )
}

export default withTrans(WhatWeOfferViewMobile)
